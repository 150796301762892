import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './plugins/element.js'
import serverJs from './utils/server.js'
import 'font-awesome/css/font-awesome.css';
import Api from './api/Api.js'
Vue.use(Api);
Vue.prototype._Api = Api;
Vue.use(serverJs);
Vue.prototype.SV = serverJs;
Vue.config.productionTip = false
import cm from './utils/common.js'
Vue.prototype.CM = cm;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
router.beforeEach((to, from, next) => {
		// var model = document.getElementsByClassName('v-modal')[0];
		// var drawer = document.getElementsByClassName('el-drawer__wrapper')[0];
		// if(model){
		// 	document.body.removeChild(model);
		// }
	  if (to.path == '/login') {
			if(localStorage.getItem('loginData')){
				next({
					path: '/index'
				})
			}else{
				next();
			}
	  }else{
			if(localStorage.getItem('loginData')){
				next();
			}else{
				if(to.path == '/cancelpage'){
					next();
				}else if (to.path == "/orderlist") {
					//当离开的时候不是去首页的时候开启缓存
					from.meta.isBack = false;
					next();
				}else{
					next({
						path: '/login'
					})
				}
			}
	  }
	  if (to.meta.title) {
	    document.title = to.meta.title
	  }else{
	    document.title = "SmartWe PMS"
	  }
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
console.log = function(){

};
function setCookie(c_name,value,day,domain){
		var exdate=new Date();
		exdate.setTime(exdate.getTime()+day*24*60*60*1000);
		document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
}
function getCookie(cname){
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++)
	{
		var c = ca[i].trim();
		if (c.indexOf(name)==0) return c.substring(name.length,c.length);
	}
	return "";
}
